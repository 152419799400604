import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { forkJoin, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user/user.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { TranslationService } from '../shared/translation/translation.service';
import { LanguageService } from './language/language.service';
import { ArrayService } from './array.service';
import { UserPnpService } from './user/user-pnp.service';
import { CacheControlService } from './cache-control.service';
import { UserInfoService } from './user/user-info.service';
import { CommonDataService } from './common-data.service';
import { OneSignalService } from './one-signal.service';
import { AbTestService } from '../ab-test/ab-test.service';
import { InstallAppService } from './install-app.service';

@Injectable({
  providedIn: 'root'
})
export class DataPreloaderService {

  /**
   * List of observables that need to be preloaded before app start
   */
  private _dataToPreload: Array<any> = [
    this._translate.getUserTranslates$().pipe(first()),
    this._abTest.loadABTest$('DEVMANEKI7031').pipe(first()),
    this._abTest.loadABTest$('DEVMANEKI_REG_FROM_LAND').pipe(first()),
    this._installApp.checkIfShowInstallAppByCMS().pipe(first())
  ];

  /**
   * Is data loaded
   */
  private _loaded: boolean = null;

  constructor(
    private _env: EnvironmentService,
    private _user: UserService,
    private _translate: TranslationService,
    private _language: LanguageService,
    private _abTest: AbTestService,
    private _array: ArrayService,
    private _pnp: UserPnpService,
    private _cache: CacheControlService,
    private _userInfo: UserInfoService,
    private _commonData: CommonDataService,
    private _oneSignal: OneSignalService,
    private _installApp: InstallAppService,
  ) {
    console.log(`Data ser inited`);
  }

  /**
   * Access to _loaded
   */
  get loaded(): boolean {
    return this._loaded;
  }

  /**
   * For main route resolving
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this._user.auth$.pipe(
      switchMap(() => this._env.env$),
      first(),
      tap(() => {
        if (!this._language.current) {
          const params = this._array.routeToArray(state.url);
          const routeLang = params[0];
          this._language.changeLang(this._language.detectUserLang(routeLang, this._env.env.locale.short, this._env.env.languageList));
        }
      }),
      tap(() => {
        if (this._env.env.country.short === 'nl') {
          this._dataToPreload.push(this._commonData.getNlStags());
        } else {
          this._userInfo.isSignupRestricted$.next(false);
        }
      }),
      switchMap(() => this._cache.updateCache()),
      switchMap(() => forkJoin(this._dataToPreload)),
      tap(() => {
        setTimeout(() => {
          this._loaded = true;
        }, 1000);
        this._pnp.initPnp(this._user.auth);
        this._oneSignal.handleId();
      })
    );
  }
}
